import React from "react";
import {Helmet} from "react-helmet";
import {graphql, useStaticQuery} from "gatsby";
import {useLocation} from "@reach/router";

const Seo = ({title, description}) => {
   const {pathname} = useLocation();
   const {site} = useStaticQuery(query);

   const {
       defaultTitle,
       defaultDescription,
       siteUrl,
       githubUsername,
   } = site.siteMetadata;

   const seo = {
      title: title || defaultTitle,
      description: description || defaultDescription,
      url: `${siteUrl}${pathname}`
   };

    return (
        <Helmet title={seo.title}>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
                <meta property="og:description" content={seo.description} />
            )}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="github:card" content="summary_large_image" />
            {githubUsername && (
                <meta name="github:user" content={githubUsername} />
            )}
            {seo.title && <meta name="github:title" content={seo.title} />}
            {seo.description && (
                <meta name="github:description" content={seo.description} />
            )}
        </Helmet>
    )
}

const query = graphql`
   query SeoQuery {
      site {
         siteMetadata {
            defaultTitle: title
            defaultDescription: description,
            siteUrl: url
            githubUsername
         }
      }
   }
`;

export default Seo;
